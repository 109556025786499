import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
import Footer from '../../Components/Footer'

import './style.css'

function Root() {

    return (
        <div className="viewPage">
            <Container className="pageContent">
                <Row xs={1} md={2} style={{ justifyContent: 'center' }}>
                    {/* <a href="https://yuhayman.com"> */}
                    <Image src="./images/kim.png" fluid />
                    {/* </a> */}
                </Row>
                <Row xs={1} md={2} style={{ justifyContent: 'center' }}>
                    <a href="https://danny.yuhayman.com">
                        <Image src="./images/danny.png" fluid />
                    </a>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}

export default Root