import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NotFound from './Pages/NotFound'
import Root from './Pages/Root'

import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Root />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

    </Router>

  );
}

export default App;